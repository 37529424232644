header {
  width: 100%;
  height: 160px;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
header .Upper-part {
  width: 100%;
  height: 20%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  box-shadow: 0px 1px 1px 0px rgba(68, 68, 68, 0.2);
}
header .Upper-part .upper-part-left {
  margin-left: 80px;
  display: flex;
  margin-bottom: 4px;
}
header .Upper-part .upper-part-left .UpperPart-elm-left {
  margin-right: 30px;
}
header .Upper-part .upper-part-right {
  color: rgb(0, 165, 110);
  margin-right: 80px;
  justify-self: end;
  display: flex;
  margin-bottom: 4px;
}
header .Upper-part .upper-part-right .UpperPart-elm-right {
  margin-left: 30px;
}
header .Middle-part {
  width: 100%;
  height: 50%;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}
header .Middle-part .Name {
  margin-left: 80px;
}
header .Middle-part .search-tool {
  border: 2px solid rgb(197, 197, 197);
  background-color: rgb(242, 242, 242);
  padding: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 420px;
}
header .Middle-part .search-tool .category {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 2px;
  margin-left: 10px;
}
header .Middle-part .search-tool .category .all-category-icon {
  font-size: 12px;
  color: rgb(0, 172, 115);
  margin-left: 5px;
}
header .Middle-part .search-tool input {
  width: 250px;
  background-color: inherit;
  text-decoration: none;
  border: none;
}
header .Middle-part .search-tool input:focus {
  outline: none;
}
header .Middle-part .middlepart-icons {
  margin-right: 80px;
  justify-self: end;
  display: flex;
}
header .Middle-part .middlepart-icons .profileIcon {
  width: 20px;
  margin-right: 16px;
}
header .Middle-part .middlepart-icons .cartSec {
  position: relative;
}
header .Middle-part .middlepart-icons .cartSec .numItems {
  position: absolute;
  left: -8px;
  bottom: -8px;
}
header .Middle-part .middlepart-icons .cartSec .num {
  font-family: "Poppins";
  position: absolute;
  left: 0px;
  bottom: -3px;
  color: white;
  font-weight: bold;
}
header .Middle-part .middlepart-icons .cartSec .shoppingCartIcon {
  width: 24px;
}
header .Bottom-part {
  justify-self: flex-end;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 80px;
  background-color: rgb(233, 233, 233);
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 65px;
       column-gap: 65px;
}
header .Bottom-part .elem {
  height: -moz-fit-content;
  height: fit-content;
  padding: 12px 8px 12px 8px;
  position: relative;
}
header .Bottom-part .elem .dropDownNavigation {
  display: none;
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  left: 0;
  margin-top: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
header .Bottom-part .elem .dropDownNavigation .dropDownLink {
  text-decoration: none;
  color: black;
  height: 100%;
  padding: 12px 24px 10px 8px;
}
header .Bottom-part .elem .dropDownNavigation .dropDownLink:hover {
  background-color: rgb(161, 248, 212);
  border-radius: 10px;
}
header .Bottom-part .elem .Bottom-icons {
  font-size: 12px;
  color: rgb(0, 130, 86);
  margin-left: 5px;
  cursor: pointer;
}
header .Bottom-part .elem:hover {
  background-color: white;
}
header .Bottom-part .elem:hover .dropDownNavigation {
  display: flex;
  flex-direction: column;
  float: none;
  background-color: white;
}/*# sourceMappingURL=header.css.map */