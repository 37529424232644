$borderColor: rgb(205, 205, 205);
.extraDetails {
    display: flex;
    column-gap: 32px;

    .leftSec {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .rightSec {

        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}

.box {
    cursor: pointer;
    height: 20px;
    width: 18px;
    border: 2px solid $borderColor;
    border-radius: 5px;
}

.circle{
    cursor: pointer;
    font-size: 22px;
    border: 1px solid $borderColor;
    color: transparent;
    border-radius: 50%;
}