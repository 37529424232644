.Checkout {
  margin-top: 200px;
  display: flex;
  margin-left: 80px;
  -moz-column-gap: 32px;
       column-gap: 32px;
}
.Checkout .orderSummary .titleSec, .Checkout .billingSec .confirmation .titleSec, .Checkout .billingSec .AdditionalInfo .titleSec, .Checkout .billingSec .payementMethod .titleSec, .Checkout .billingSec .shippingMethod .titleSec, .Checkout .billingSec .billingInfo .titleSec {
  display: flex;
  flex-direction: column;
}
.Checkout .orderSummary .titleSec .title, .Checkout .billingSec .confirmation .titleSec .title, .Checkout .billingSec .AdditionalInfo .titleSec .title, .Checkout .billingSec .payementMethod .titleSec .title, .Checkout .billingSec .shippingMethod .titleSec .title, .Checkout .billingSec .billingInfo .titleSec .title {
  font-size: 24px;
  font-weight: bold;
}
.Checkout .orderSummary .titleSec .legend, .Checkout .billingSec .confirmation .titleSec .legend, .Checkout .billingSec .AdditionalInfo .titleSec .legend, .Checkout .billingSec .payementMethod .titleSec .legend, .Checkout .billingSec .shippingMethod .titleSec .legend, .Checkout .billingSec .billingInfo .titleSec .legend {
  opacity: 0.5;
  font-size: 12px;
}
.Checkout .billingSec {
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 48px;
}
.Checkout .billingSec .billingInfo .info {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 24px;
  -moz-column-gap: 18px;
       column-gap: 18px;
  row-gap: 32px;
}
.Checkout .billingSec .billingInfo .info .inputFields {
  display: flex;
  flex-direction: column;
}
.Checkout .billingSec .billingInfo .info .inputFields span {
  font-weight: bold;
}
.Checkout .billingSec .billingInfo .info input {
  padding: 0px 16px;
  width: 319px;
  height: 48px;
  border-radius: 16px;
  outline: none;
  border: 2px solid #D1D1D1;
}
.Checkout .billingSec .billingInfo button {
  border-radius: 10px;
  border: 2px solid #D1D1D1;
  padding: 15px;
  margin-top: 24px;
  background-color: #F9F9F9;
  display: flex;
  align-items: flex-end;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.Checkout .billingSec .billingInfo button span {
  font-size: 15px;
}
.Checkout .billingSec .shippingMethod {
  margin-top: 24px;
}
.Checkout .billingSec .shippingMethod .options {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.Checkout .billingSec .shippingMethod .options .shippingOption {
  cursor: pointer;
  width: 90%;
  border: 2px solid #D1D1D1;
  background-color: #F9F9F9;
  border-radius: 16px;
  height: 48px;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  padding: 0px 24px 0px 24px;
}
.Checkout .billingSec .shippingMethod .options .shippingOption img {
  justify-self: end;
  max-width: 60px;
}
.Checkout .billingSec .shippingMethod .options .shippingOption .companyName {
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;
  align-items: center;
}
.Checkout .billingSec .shippingMethod .options .shippingOption .companyName .circle {
  border: 2px solid rgb(204, 204, 204);
  border-radius: 50%;
  color: transparent;
}
.Checkout .billingSec .shippingMethod .options .shippingOption .price {
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;
}
.Checkout .billingSec .shippingMethod .options .shippingOption .price .addedMoney {
  color: rgb(0, 168, 25);
  font-weight: bold;
}
.Checkout .billingSec .payementMethod {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
}
.Checkout .billingSec .payementMethod label {
  font-size: 12px;
  font-weight: bold;
}
.Checkout .billingSec .payementMethod .card .bottomPart input, .Checkout .billingSec .payementMethod .card .cardNumberSec input {
  border-radius: 10px;
  height: 32px;
  border: 2px solid rgb(190, 190, 190);
  outline: none;
  background-color: #F9F9F9;
  padding: 0px 16px;
}
.Checkout .billingSec .payementMethod .card .bottomPart input:focus, .Checkout .billingSec .payementMethod .card .cardNumberSec input:focus {
  background-color: transparent;
}
.Checkout .billingSec .payementMethod .card {
  width: 500px;
  border-radius: 10px;
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  padding: 16px 19px 16px 19px;
  border: 1px solid rgb(190, 190, 190);
}
.Checkout .billingSec .payementMethod .card .upperPart {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
}
.Checkout .billingSec .payementMethod .card .upperPart img {
  justify-self: flex-end;
}
.Checkout .billingSec .payementMethod .card .upperPart .textArea {
  display: flex;
  -moz-column-gap: 8px;
       column-gap: 8px;
  align-items: center;
}
.Checkout .billingSec .payementMethod .card .cardNumberSec {
  width: 100%;
}
.Checkout .billingSec .payementMethod .card .cardNumberSec input::-webkit-outer-spin-button,
.Checkout .billingSec .payementMethod .card .cardNumberSec input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Checkout .billingSec .payementMethod .card .cardNumberSec input {
  width: 468px;
}
.Checkout .billingSec .payementMethod .card .bottomPart {
  display: flex;
  width: 100%;
}
.Checkout .billingSec .payementMethod .card .bottomPart .cardHolderSec input {
  width: 227.2727272727px;
}
.Checkout .billingSec .payementMethod .card .bottomPart .expirationDateSec {
  align-self: center;
  margin-left: 8px;
}
.Checkout .billingSec .payementMethod .card .bottomPart .expirationDateSec input {
  width: 100px;
}
.Checkout .billingSec .payementMethod .card .bottomPart .cvcSec input::-webkit-outer-spin-button,
.Checkout .billingSec .payementMethod .card .bottomPart .cvcSec input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Checkout .billingSec .payementMethod .card .bottomPart .cvcSec input {
  width: 48px;
}
.Checkout .billingSec .payementMethod .paypal {
  width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #D1D1D1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 16px 16px 16px 16px;
}
.Checkout .billingSec .payementMethod .paypal .inputSec {
  margin: 16px 0px 16px 0px;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.Checkout .billingSec .payementMethod .paypal .inputSec input {
  padding: 0px 0px 0px 16px;
  width: 200px;
  outline: none;
  border-radius: 10px;
  height: 32px;
  border: 2px solid #D1D1D1;
}
.Checkout .billingSec .payementMethod .paypal .upperPart {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
}
.Checkout .billingSec .payementMethod .paypal .upperPart img {
  justify-self: flex-end;
}
.Checkout .billingSec .payementMethod .paypal button {
  width: 120px;
  margin: 0px auto 0px auto;
  border: 2px solid #46760A;
  outline: none;
  background-color: rgb(16, 155, 109);
  color: #ffffff;
  border-radius: 10px;
  height: 32px;
  cursor: pointer;
}
.Checkout .billingSec .payementMethod .bitcoin {
  width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  padding: 16px 16px 16px 16px;
}
.Checkout .billingSec .payementMethod .bitcoin .upperPart {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
}
.Checkout .billingSec .payementMethod .bitcoin .upperPart img {
  justify-self: flex-end;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo {
  margin: 0px auto 0px auto;
  width: -moz-fit-content;
  width: fit-content;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .title {
  opacity: 0.7;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .walletAddress, .Checkout .billingSec .payementMethod .bitcoin .walletInfo .amounToPay {
  width: 300px;
  border: 2px solid #D1D1D1;
  border-radius: 10px;
  background-color: #F9F9F9;
  cursor: pointer;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .amounToPay {
  margin-bottom: 16px;
  padding: 8px 0px 8px 0px;
  display: grid;
  grid-template-columns: 60% 30% 10%;
  align-items: center;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .amounToPay .btcValue {
  justify-self: center;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .amounToPay .usdValue {
  color: rgb(16, 155, 109);
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .walletAddress {
  padding: 8px 0px 8px 0px;
  display: grid;
  grid-template-columns: 90% 10%;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .walletAddress span {
  justify-self: center;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .timer {
  text-align: center;
  margin: 8px 0px 0px 0px;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .timer .time {
  font-weight: bold;
}
.Checkout .billingSec .payementMethod .bitcoin .walletInfo .timer .legend {
  opacity: 0.5;
}
.Checkout .billingSec .payementMethod .barDesign {
  height: 48px;
  width: 500px;
  display: grid;
  align-items: center;
  border-radius: 10px;
  padding: 0px 16px 0px 16px;
  grid-template-columns: auto auto;
  border: 1px solid rgb(190, 190, 190);
  cursor: pointer;
}
.Checkout .billingSec .payementMethod .barDesign .textArea {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.Checkout .billingSec .payementMethod .barDesign img {
  justify-self: flex-end;
}
.Checkout .billingSec .AdditionalInfo {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
}
.Checkout .billingSec .AdditionalInfo textarea {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  border: 1px solid rgb(190, 190, 190);
  outline: none;
  resize: none;
  padding: 8px;
}
.Checkout .billingSec .confirmation .termsToCheck .termCondition, .Checkout .billingSec .confirmation .termsToCheck .marketingEmails {
  display: flex;
  -moz-column-gap: 8px;
       column-gap: 8px;
  background-color: #F9F9F9;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 10px;
  padding: 8px 16px 8px 16px;
  width: -moz-fit-content;
  width: fit-content;
}
.Checkout .billingSec .confirmation .termsToCheck {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.Checkout .billingSec .btncompleted {
  margin-top: 24px;
  width: 218px;
  height: 56px;
  border-radius: 12px;
  border: 2px solid #46760A;
  background-color: #6A983C;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}
.Checkout .billingSec .btncompleted:hover {
  animation: btnComplete 0.5s forwards ease-in-out;
}
@keyframes btnComplete {
  to {
    background-color: #ffffff;
    color: #46760A;
  }
}
.Checkout .orderSummary {
  border: 1px solid #D1D1D1;
  border-radius: 12px;
  padding: 32px 16px 32px 16px;
  height: -moz-fit-content;
  height: fit-content;
}
.Checkout .orderSummary .titleSec {
  margin: 0px 0px 24px 0px;
}
.Checkout .orderSummary .products {
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;
  height: 170px;
  width: 436px;
  border-bottom: 1px solid #F9F9F9;
  padding-bottom: 16px;
}
.Checkout .orderSummary .products .leftSec .baguette {
  max-width: 100px;
  border-radius: 10px;
}
.Checkout .orderSummary .products .leftSec .controlBtns {
  display: flex;
  flex-direction: column;
}
.Checkout .orderSummary .products .leftSec .controlBtns img {
  max-width: 15px;
}
.Checkout .orderSummary .products .leftSec .controlBtns .xIcon {
  font-size: 12px;
}
.Checkout .orderSummary .products .leftSec .controlBtns div {
  display: flex;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
}
.Checkout .orderSummary .products .rightSec {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  width: 100%;
}
.Checkout .orderSummary .products .rightSec .title {
  font-weight: bold;
  font-size: 18px;
}
.Checkout .orderSummary .products .rightSec .pricingPieces {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .price {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .price .currentPrice {
  font-size: 18px;
  color: #6A983C;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .price .oldPrice {
  font-size: 11px;
  text-decoration: line-through;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .pieces {
  width: 92px;
  height: 32px;
  justify-self: end;
  align-items: center;
  border-radius: 12px;
  background-color: #F9F9F9;
  border: 1px solid #D1D1D1;
  display: grid;
  grid-template-columns: auto auto auto;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .pieces .numPieces {
  color: #D1D1D1;
  justify-self: center;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .pieces .line {
  height: 80%;
  width: 1px;
  background-color: #D1D1D1;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .pieces i {
  font-size: 10px;
}
.Checkout .orderSummary .products .rightSec .pricingPieces .pieces .pcs {
  margin-right: 4px;
  font-weight: bold;
}
.Checkout .orderSummary .products .rightSec .extraDetails {
  display: flex;
  flex-direction: column;
}
.Checkout .orderSummary .subTotal {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: 16px;
  row-gap: 10px;
  font-size: 12px;
}
.Checkout .orderSummary .subTotal div {
  display: grid;
  grid-template-columns: auto auto;
}
.Checkout .orderSummary .subTotal div .numericalVal {
  justify-self: end;
}
.Checkout .orderSummary .applyPromo {
  margin: 16px 0px 16px 0px;
  height: 42px;
  display: grid;
  grid-template-columns: 75% auto;
  background-color: #F9F9F9;
  border: 1px solid #D1D1D1;
  border-radius: 12px;
}
.Checkout .orderSummary .applyPromo input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0px 0px 0px 21px;
}
.Checkout .orderSummary .applyPromo div {
  align-self: center;
  font-weight: bold;
  margin: 0px 16px 0px 0px;
  justify-self: flex-end;
  cursor: pointer;
}
.Checkout .orderSummary .totalOrder {
  display: grid;
  grid-template-columns: auto auto;
}
.Checkout .orderSummary .totalOrder div {
  display: flex;
  flex-direction: column;
}
.Checkout .orderSummary .totalOrder div .guarantee {
  color: #6A983C;
}
.Checkout .orderSummary .totalOrder .totalPrice {
  font-size: 26px;
  align-self: flex-end;
  justify-self: end;
  font-weight: bold;
  color: #6A983C;
}/*# sourceMappingURL=billing.css.map */