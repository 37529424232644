$borderColor: rgb(205, 205, 205);
$greenBtn: rgb(16, 155, 109);

%DispFlexColumn {
    display: flex;
    flex-direction: column;
}

.Title {
    height: 200px;
    margin-left: 80px;
    margin-top: 200px;

    .CategoryPath {
        height: 25px;

        p {
            span {
                opacity: 0.5;
            }
        }
    }

    .choosenCategory {
        display: grid;
        grid-template-columns: auto auto;

        .ViewStyle {
            margin-right: 80px;
            justify-self: end;
            display: flex;
            column-gap: 16px;
            align-items: center;

            .gridView {
                cursor: pointer;
            }

            .listView {
                cursor: pointer;
            }

            .productAvailable {
                span {
                    background-color: rgba(0, 153, 102, 0.2);
                    border-radius: 10px;
                    padding: 0px 6px 2px 6px;
                    color: $greenBtn;
                    font-weight: bold;
                }
            }
        }
    }

    .filters {
        display: flex;
        width: 100%;
        column-gap: 16px;

        %filterBoxDesign {
            border-radius: 10px;
            padding: 8px 16px 8px 16px;
            border: 2px solid $borderColor;
        }

        .filter3 {
            @extend %filterBoxDesign;

            .leftSide {
                .box {
                    border-radius: 5px;
                    height: 20px;
                    width: 20px;
                    border: 2px solid $borderColor;
                    cursor: pointer;
                }
            }
        }

        .AscDescending {

            @extend %filterBoxDesign;
            display: flex;
            column-gap: 12px;
            align-items: center;
            color: rgb(154, 154, 154);


            div {
                cursor: pointer;
            }

            .circle {
                border: 2px solid $borderColor;
                border-radius: 50%;
                color: transparent;
            }

            .dot {
                color: $greenBtn;
                font-size: 19px;
            }

        }

        .filter2 {

            @extend %filterBoxDesign;
            display: flex;
            align-items: center;
            column-gap: 8px;

            .box {
                border-radius: 5px;
                height: 22px;
                width: 20px;
                border: 2px solid rgb(182, 180, 180);
                cursor: pointer;
            }

            span {
                margin-bottom: 2px;
            }

            .square {
                color: transparent;
                border: 2px solid $borderColor;
            }

            .nbm {
                border: 2px solid $borderColor;
                font-size: 12px;
                border-radius: 10px;
                padding: 0px 2px 0px 2px;
                font-weight: bold;
            }

        }

        .filter3 {

            @extend %filterBoxDesign;
            width: fit-content;
            display: flex;
            column-gap: 16px;
            align-items: center;

            span {
                padding-bottom: 2px;
            }

            .leftSide {
                display: flex;
                column-gap: 6px;
                align-items: center;

                span {
                    padding-bottom: 2px;
                }

                .squareCheck {
                    cursor: pointer;
                    color: $greenBtn;
                    font-size: 22px
                }
            }

            .selectSection {
                position: relative;
                display: flex;
                align-items: center;
                column-gap: 5px;
                cursor: pointer;
                height: inherit;

                .selectArrow {
                    font-size: 10px;
                }

                .listFilters {
                    position: absolute;
                    display: none;
                    top: 24px;
                    right: 0px;
                    width: 150px;
                    word-wrap: break-word;
                    border-radius: 10px;
                    font-weight: bold;
                    font-size: 14px;

                    div {
                        cursor: pointer;
                        padding: 10px;
                    }

                    div:hover {
                        color: white;
                        background-color: rgb(137, 137, 137);
                        border-radius: 10px;
                    }
                }
            }

            .selectSection:hover .listFilters {
                display: block;
                background-color: rgb(255, 255, 255);
                box-shadow: 0px 0px 10px rgb(95, 95, 95, 0.5);
            }
        }
    }
}

.appliedFilters {
    margin-top: 16px;
    display: flex;
    column-gap: 8px;

    .selectedFilters {
        background-color: aquamarine;
        display: flex;
        column-gap: 5px;
        align-items: center;
        padding: 0px 5px 3px 5px;
        border-radius: 10px;

        i {
            color: rgb(155, 155, 155);
            margin-top: 4px;
            font-size: 10px;
            align-self: center;
            cursor: pointer;
            color: black;
        }

        span {
            color: rgb(0, 109, 73);
            font-weight: bold;
            font-size: 14px;
        }
    }
}

.SearchSection {
    height: fit-content;
    display: grid;
    grid-template-columns: auto 75%;

    .ProductSearchResults {
        margin-right: 40px;

        .ProductDisplay {
            border: 2px solid $borderColor;
            margin: 16px;
            height: fit-content;
            display: grid;
            grid-template-columns: auto 35% auto;
            column-gap: 24px;
            border-radius: 10px;
            position: relative;

            img {
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
                max-height: 280px;
                max-width: 280px;
                margin: auto 0 auto 0;
            }   
            .imgBlurBackground{
                position: absolute;
                z-index: -1;
                height: 100%;
                filter: blur(2px);
            }

            .middleSection {
                .productNameRating {
                    @extend %DispFlexColumn;
                    row-gap: 4px;
                    margin-top: 10px;

                    .productTitle {
                        font-weight: bold;
                        font-size: 20px;
                    }
                }

                .productInfo {
                    display: flex;
                    column-gap: 32px;
                    margin-top: 16px;
                    .rightSide {
                        @extend %DispFlexColumn;
                        row-gap: 8px;
                    }
                }
            }

            .buySection {
                @extend %DispFlexColumn;
                row-gap: 16px;

                .PriceSection {
                    @extend %DispFlexColumn;
                    margin-top: 10px;

                    .price {
                        font-weight: bold;
                        font-size: 20px;
                    }

                    .discount {
                        font-size: 13px;
                        text-decoration: line-through;
                        font-weight: bold;
                        opacity: 0.5;
                    }
                }

                .deliveryInfo {
                    display: flex;
                    flex-direction: column;
                    opacity: 0.5;

                    .shippingStatus {
                        font-weight: bold;
                    }

                    .shippingDelay {
                        font-size: 13px;
                    }
                }

                .addToWishList {
                    @extend %DispFlexColumn;

                    .btnWishList {
                        cursor: pointer;
                        display: flex;
                        column-gap: 5px;
                        margin-top: 8px;
                        width: fit-content;
                        background-color: rgb(206, 206, 206);
                        border-radius: 10px;
                        border: 2px solid transparent;
                        padding: 8px 16px 10px 16px;
                    }

                    .btnWishList:hover {
                        animation: wishBtn 0.4s forwards ease-out;
                    }

                    @keyframes wishBtn {
                        100% {
                            background-color: white;
                            color: $greenBtn;
                            border: 2px solid rgb(0, 92, 61);
                        }
                    }

                    .btnproductDetails {
                        color: white;
                        border: 2px solid;
                        font-weight: bold;
                        padding: 8px 16px 10px 16px;
                        width: fit-content;
                        background-color: $greenBtn;
                        border-radius: 10px;

                        .btnProductDetailsLink {
                            text-decoration: none;
                            color: white;
                        }

                        i {
                            font-size: 12px;
                        }

                    }

                    .btnproductDetails:hover {
                        cursor: pointer;
                        animation: productDetails;
                        animation-duration: 0.4s;
                        animation-fill-mode: forwards;
                        animation-timing-function: ease-out;
                    }

                    @keyframes productDetails {
                        100% {
                            background-color: white;
                            color: $greenBtn;
                            border: 2px solid $greenBtn;
                        }
                    }

                    .btnproductDetails:hover .btnProductDetailsLink {
                        cursor: pointer;
                        animation: link;
                        animation-duration: 0.4s;
                        animation-fill-mode: forwards;
                        animation-timing-function: ease-out;
                    }

                    @keyframes link {
                        100% {
                            color: $greenBtn;
                        }
                    }

                }
            }
        }
    }

    .productDisplayGrid {
        margin-right: 40px;
        display: flex;
        height: fit-content;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 24px;
    }
}

.LeftSideFilters {
    padding: 0px 0px 0px 80px;

    .priceSettings {
        .PriceRange {
            display: flex;

            %priceRange {
                @extend %DispFlexColumn;
                font-size: 12px;
                font-weight: bold;
            }

            %title {
                margin-left: 4px;
            }

            %priceRangeInput {
                width: 64px;
                height: 32px;
                border-radius: 10px;
                outline: none;
                border: 2px solid $borderColor;
            }

            .maxPrice {
                @extend %priceRange;
                margin-left: 16px;

                span {
                    @extend %title;
                }

                input {
                    @extend %priceRangeInput;
                }
            }

            .minPrice {
                @extend %priceRange;

                span {
                    @extend %title;
                }

                input {
                    @extend %priceRangeInput;
                }
            }
        }
    }

    .submittingFilter {
        display: flex;
        column-gap: 10px;
        margin-top: 16px;
        font-weight: bold;

        .apply {
            cursor: pointer;
            background-color: $greenBtn;
            width: fit-content;
            padding: 6px 10px 10px 10px;
            border-radius: 10px;
            border: 2px solid green;
            color: white;
        }

        .apply:hover {
            animation: applyBtn 0.4s forwards ease-out;
        }

        @keyframes applyBtn {
            to {
                background-color: white;
                color: $greenBtn;
                border: 2px solid $greenBtn;
            }
        }

        .reset {
            cursor: pointer;
            background-color: rgb(212, 212, 212);
            padding: 10px 10px 10px 10px;
            width: fit-content;
            border-radius: 10px;
            color: rgb(101, 101, 101);
            border: 2px solid transparent;
        }

        .reset:hover {
            animation: reset 0.4s forwards ease-out;
        }

        @keyframes reset {
            to {
                background-color: white;
                color: $greenBtn;
                border: 2px solid $greenBtn;
            }
        }
    }

    .Ratings {

        .rating {
            display: flex;
            row-gap: 10px;
            column-gap: 6px;

            .boxCheckSec {
                display: flex;
                flex-direction: column;
                row-gap: 6px;
            }

            .starSec {
                display: flex;
                flex-direction: column;
                row-gap: 7px;
            }

            .level {
                display: flex;
                align-items: center;
                column-gap: 10px;

                .stars {
                    display: flex;
                    column-gap: 5px;
                }
            }
        }
    }

    .Brands {
        @extend %DispFlexColumn;
        row-gap: 10px;
        justify-self: center;

        .brand {
            display: flex;
            column-gap: 10px;
            align-items: center;

            .checkedSquare {
                cursor: pointer;
                color: $greenBtn;
                font-size: 24px;
            }



            .box:hover {
                border: 2px solid rgb(118, 118, 118);
            }
        }
    }

    .categories {
        @extend %DispFlexColumn;
        row-gap: 6px;

        .category {
            display: grid;
            grid-template-columns: auto auto;
            width: 64%;

            .availableElm {
                justify-self: end;
                width: auto;
                background-color: aquamarine;
                border-radius: 10px;
                padding: 2px 8px 2px 8px;
                color: $greenBtn;
            }
        }
    }
}

.BottomNavigation {
    display: grid;
    align-content: center;
    grid-template-columns: auto auto auto;
    height: 80px;
    margin-bottom: 20px;
    width: 100%;

    .page {
        display: flex;
        column-gap: 10px;
        margin-left: 80px;
        align-self: center;
    }

    .moreProducts {
        cursor: pointer;
        color: white;
        display: flex;
        justify-self: center;
        column-gap: 5px;
        width: fit-content;
        height: fit-content;
        padding: 7px 16px 10px 16px;
        background-color: $greenBtn;
        border-radius: 10px;
        font-weight: bold;

        i {
            margin-top: 3px;
            font-size: 13px;
        }
    }

    .allProductFound {
        display: flex;
        column-gap: 6px;
        justify-self: end;
        margin-right: 20px;
        align-self: center;

        .product {
            align-self: center;
            font-size: 12px;
        }

        .num {
            color: $greenBtn;
            width: fit-content;
            height: fit-content;
            background-color: rgb(167, 255, 186);
            padding: 2px 6px 2px 6px;
            border-radius: 10px;
            font-weight: bold;
        }
    }
}