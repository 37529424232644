.homepage {
    width: 100%;
    resize: none;
    overflow-x: auto;
}

.CategoryMenu {
    margin-top: 180px;
    display: flex;
    column-gap: 24px;
}

.categories {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.categories>a {
    color: rgb(0, 165, 110);
}

.CommonOptionSec {
    width: 300px;
    height: 280px;
    margin-left: 80px;
}

.CommonOptionSec>.title {
    margin: 16px 0px 16px 0px;
}

.CommonOptionSec>.title>span {
    font-weight: bold;
    font-size: 20px;
}

.CommonOptionSec>button {
    border: none;
    height: 40px;
    border-radius: 10px;
    margin-top: 32px;
}

.CommonOptionSec>button>span {
    font-size: 16px;
    font-weight: bold;
    margin-left: 8px;
}

.CommonOptionSec>button>.icon {
    margin: 0px 8px 0px 8px;
}

/* animation of button*/
.CommonOptionSec>button:hover {
    animation: moreInfo 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    cursor: pointer;
}

@keyframes moreInfo {
    100% {
        background-color: rgb(62, 62, 62);
        color: rgb(255, 255, 255);
    }
}

/*--------------------*/


.BannerSubFocus {
    border-radius: 16px;
    background-color: aquamarine;
    width: 360px;
    height: 280px;
    display: grid;
    grid-template-rows: auto auto;
}

.BannerSubFocus>button {
    align-self: end;
    background-color: transparent;
    border: 3px solid rgb(56, 56, 56);
    height: 40px;
    border-radius: 10px;
    margin: 16px 0px 32px 16px;
    width: fit-content;
    color: rgb(56, 56, 56);
}

.BannerSubFocus>button>a {
    margin: 0px 0px 0px 4px;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
}

.BannerSubFocus>button>.icon {
    margin: 0px 8px 0px 8px;
}

/* Annimation of button */
.BannerSubFocus>button:hover {
    animation-name: bannerButton;
    cursor: pointer;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes bannerButton {
    100% {
        background-color: rgb(29, 96, 97);
        border: transparent;
        color: rgb(255, 255, 255);
    }
}

/* -------------------*/
.BannerSubFocus>.txt-area {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}

.BannerSubFocus>.txt-area {
    margin: 40px 0px 0px 16px;
}

.BannerSubFocus>.txt-area>.primary-txt {
    font-weight: bold;
    font-size: 20px;
}

.BestSellingProducts {
    margin-top: 48px;
    display: flex;
    column-gap: 24px;
}

.ProductSect {
    border: 1px solid rgb(200, 200, 200);
    border-radius: 16px;
    display: grid;
    height: fit-content;
    padding-bottom: 10px;
    width: 240px;
    background-color: transparent;
    grid-template-rows: auto auto auto;
}

.ProductSect>img {
    width: 90%;
    border-radius: 16px;
    display: block;
    margin: 10px auto 0px auto;
}

.ProductSect>.titleDescription {
    margin: 10px 0px 10px 16px;
    display: flex;
    flex-direction: column;
}

.ProductSect>.titleDescription>.productTitle {
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 10px;
    color: black;
}

.ProductSect>.titleDescription>.productDescrip {
    font-size: 12px;
}

.ProductSect>.buyingSection {
    display: grid;
    grid-template-columns: auto auto;
}

.ProductSect>.buyingSection>.price {
    margin: 0px 0px 0px 16px;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.ProductSect>.buyingSection>.price>.current-price {
    font-weight: bold;
    font-size: 20px;
}

.ProductSect>.buyingSection>.price>.old-price {
    font-weight: bold;
    font-size: 13px;
    color: rgb(174, 174, 174);
    text-decoration: line-through;
}

.ProductSect>.buyingSection>.btn-buy {
    width: fit-content;
    height: fit-content;
    padding: 10px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin: 0px 0px 0px 0px;
    border-color: rgb(0, 92, 61);
    background-color: rgb(0, 169, 73);
    align-self: center;
}

.ProductSect>.buyingSection>.btn-buy>.linkBtn {
    text-decoration: none;
    color: inherit;
}



.ProductSect>.buyingSection>.btn-buy:hover {
    cursor: pointer;
    animation: buyNow;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

@keyframes buyNow {
    100% {
        background-color: white;
        color: rgb(0, 92, 61);
        border: 2px solid rgb(0, 92, 61);
        padding: 8px;
    }
}

.BestFromFarmers {
    margin-top: 48px;
    display: flex;
    column-gap: 24px;
}


.Testimonysec {
    position: relative;
    height: inherit;
    width: 100%;
}

.TestimonialsUpperSec {
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.TestimonialsUpperSec>.btnTestimonies {
    justify-self: end;
    margin-right: 80px;
}

.TestimonialsUpperSec>p {
    margin-left: 80px;
}

.Testimonials {
    height: 250px;
    width: 100%;
}

.Testimonies {
    height: inherit;
    width: inherit;
    align-items: center;
    display: flex;
    column-gap: 16px;
    overflow-x: auto;
    z-index: -1;
}

.Testimonies::-webkit-scrollbar {
    background: transparent;
    height: 1px;
}

.TestimonyBox {
    position: relative;
    border: 3px solid rgb(227, 227, 227);
    width: fit-content;
    height: 160px;
    flex: 0 0 auto;
    border-radius: 24px;
}

#Testimonies {
    scroll-behavior: smooth;
}

.TestimonyBox>.text {
    padding: 10px 16px 0px 16px;
    width: 280px;
    text-align: center;
}

.TestimonyBox>.img {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    left: 40%;
    bottom: -25px;
}

.TestimonyBox>.nameSurname {
    text-align: center;
}

.arrowLeft {
    z-index: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 300px;
    background-image: linear-gradient(90deg, rgb(255, 255, 255), transparent);
}

.arrowLeft>.arrowBox {
    cursor: pointer;
    background-color: black;
    width: 24px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 48px;
    color: white;
    margin-top: 100px;
}

.arrowLeft>.arrowBox>.arrow {
    margin-top: 60%;
    margin-left: 10%;
}

.arrowRight>.arrowBox>.arrow {
    margin-top: 60%;
    margin-left: 25%;
}

.arrowRight {
    z-index: 0;
    position: absolute;
    height: 100%;
    width: 300px;
    background-image: linear-gradient(90deg, transparent, rgb(255, 255, 255));
    right: 0;
    top: 0;
}

.arrowRight>.arrowBox {
    cursor: pointer;
    background-color: black;
    width: 24px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 48px;
    color: white;
    margin-left: auto;
    margin-top: 100px;
}



.SectionHeadlines {
    height: fit-content;
    margin-top: 80px;
}

.SectionHeadlines>.productSec {
    margin-left: 80px;
    display: flex;
    column-gap: 24px;
}

.SectionHeadlines>.smallHeader {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
}

.SectionHeadlines>.smallHeader>p {
    margin-left: 80px;
}

.SectionHeadlines>.smallHeader>.btn-SectionHeadline {
    margin-right: 80px;
    justify-self: end;
    display: flex;
    align-items: center;
}

.BlogPost {
    margin-top: 32px;
}

.BlogPost>h3 {
    margin-left: 80px;
    margin-bottom: 32px;
}

.BlgP {
    height: 450px;
    display: flex;
    column-gap: 10px;
}

.BlgP1 {
    border-radius: 10px;
    margin-left: 80px;
    height: 400px;
    width: 550px;
    background-color: rgb(223, 223, 223);
}

.BlgP1>.Tag {
    background-color: rgb(233, 233, 233);
    width: fit-content;
    height: fit-content;
    padding: 0px 8px 2px 8px;
    border-radius: 10px;
    margin: 24px 0px 0px 16px;
    color: rgb(0, 173, 115);
    font-weight: bold;
}

.BlgP1>.videoInfo {
    position: absolute;
    width: inherit;
    height: 250px;
    margin-top: 102px;
    border-radius: 10px;
    background-image: linear-gradient(180deg, transparent, rgb(12, 12, 12));
    display: grid;
    grid-template-rows: auto 40%;
}

.BlgP1>.videoInfo>.description {
    margin: 0px 0px 0px 16px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    align-self: end;
}

.BlgP1>.videoInfo>.userInfo {
    margin: 0px 0px 0px 16px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: white;
}

.BlgP1>.videoInfo>.userInfo>.profile {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: aliceblue;
}

.BlgP2 {
    width: 350px;
}

.BlgP2>.video {
    border-radius: 10px;
    width: 350px;
    height: 220px;
    background-color: rgb(223, 223, 223);
}

.BlgP2>.vidInfo {
    margin-top: 16px;
}

.BlgP2>.vidInfo>p {
    font-weight: bold;
}

.BlgP2>.vidInfo>.vidTag {
    color: rgb(10, 208, 142);
    width: fit-content;
    padding: 0px 8px 4px 8px;
    background-color: rgb(223, 223, 223);
    border-radius: 10px;
    font-weight: bold;
}

.BlogPostSec3 {
    width: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.BlgP3>.videos {
    width: 400px;
    display: flex;
    column-gap: 10px;
}

.BlgP3>.videos>.video {
    width: 100px;
    height: 100px;
    background-color: rgb(223, 223, 223);
    border-radius: 10px;
}

.BlgP3>.videos>.videoInfo {
    width: 280px;
}

.BlgP3>.videos>.videoInfo>p {
    font-weight: bold;
}