.productDisplay {
  display: grid;
  grid-template-columns: auto 55%;
  padding-left: 100px;
}
.productDisplay .descriptionSec .shortDescription {
  width: 650px;
  font-size: 18px;
}
.productDisplay .descriptionSec .legend {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.productDisplay .descriptionSec .moreDescription {
  display: flex;
  -moz-column-gap: 48px;
       column-gap: 48px;
}
.productDisplay .descriptionSec .addToCart {
  margin-top: 24px;
  display: flex;
  height: 72px;
  width: 500px;
  border: 2px solid rgb(240, 240, 240);
  border-radius: 10px;
  -moz-column-gap: 64px;
       column-gap: 64px;
}
.productDisplay .descriptionSec .addToCart .price {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 16px;
}
.productDisplay .descriptionSec .addToCart .price .currentPrice {
  color: rgb(0, 139, 28);
  font-size: 20px;
  font-weight: bold;
}
.productDisplay .descriptionSec .addToCart .price .oldPrice {
  font-weight: bold;
  text-decoration: line-through;
  opacity: 0.5;
  font-size: 11px;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions {
  align-self: center;
  position: relative;
  display: flex;
  -moz-column-gap: 24px;
       column-gap: 24px;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .btnAddToCart {
  background-color: rgb(76, 139, 0);
  height: -moz-fit-content;
  height: fit-content;
  padding: 6px 16px 8px 16px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  border: 3px solid rgb(36, 99, 0);
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .btnAddToCart span {
  font-size: 20px;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .btnAddToCart:hover {
  cursor: pointer;
  animation: btnAddToCart;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes btnAddToCart {
  100% {
    background-color: white;
    color: rgb(0, 92, 61);
    border: 3px solid rgb(0, 92, 61);
  }
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec .piecesToAdd {
  border: 1px solid rgb(189, 189, 189);
  background-color: rgb(239, 239, 239);
  border-radius: 10px;
  width: 100px;
  height: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding-left: 20px;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec .piecesToAdd span {
  align-self: center;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec .piecesToAdd .pieces {
  align-self: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  -moz-column-gap: 6px;
       column-gap: 6px;
  cursor: pointer;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec .piecesToAdd .pieces .arrowDown {
  font-size: 12px;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec .numPieces {
  background-color: rgb(239, 239, 239);
  display: none;
  position: absolute;
  width: 48px;
  height: 200px;
  left: 20%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec .numPieces .num {
  cursor: pointer;
  padding-left: 6px;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec .numPieces .num:hover {
  background-color: rgb(51, 51, 51);
  color: white;
}
.productDisplay .descriptionSec .addToCart .addToCartOptions .piecesToAddSec:hover .numPieces {
  display: block;
}
.productDisplay .descriptionSec .addToWishList {
  margin-top: 24px;
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;
  margin-bottom: 24px;
}
.productDisplay .descriptionSec .addToWishList .wishList .heartIcon {
  width: 20px;
}
.productDisplay .descriptionSec .addToWishList .wishList span {
  font-size: 19px;
  font-weight: bold;
}
.productDisplay .descriptionSec .addToWishList .compare img {
  width: 16px;
}
.productDisplay .descriptionSec .addToWishList .compare span {
  font-size: 19px;
  font-weight: bold;
}
.productDisplay .descriptionSec .ReviewDescripQuestion {
  width: 600px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .questions {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .questions .question {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  width: 100%;
  background-color: rgb(93, 93, 93);
  border-radius: 10px;
  cursor: pointer;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .questions .question i {
  justify-self: end;
  margin-right: 10px;
  color: white;
  font-weight: bold;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .questions .question p {
  margin-left: 24px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .reviews .userInfo {
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .reviews .userInfo img {
  width: 100px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 50%;
  align-self: center;
  margin-left: 10px;
  border: 3px solid rgb(2, 173, 2);
}
.productDisplay .descriptionSec .ReviewDescripQuestion .reviews .userInfo .userReview {
  margin: 20px 0px 0px 0px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .reviews .userInfo .userReview .userName {
  font-weight: bold;
  font-size: 16px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .reviews .userInfo .userReview p {
  font-size: 14px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion .reviews .userInfo .userReview .date {
  font-size: 13px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion nav {
  display: grid;
  grid-template-columns: auto auto auto;
  height: 34px;
  align-items: start;
  padding-left: 20px;
  font-size: 18px;
  font-weight: bold;
}
.productDisplay .descriptionSec .ReviewDescripQuestion nav div {
  cursor: pointer;
  height: inherit;
}
.productDisplay .descriptionSec .ReviewDescripQuestion nav .greenSlider {
  animation-name: greenSlider;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}
.productDisplay .descriptionSec .ReviewDescripQuestion nav .graySlider {
  border-bottom: 1px solid rgb(214, 214, 214);
}
@keyframes greenSlider {
  from {
    border-bottom: 1px solid rgb(214, 214, 214);
  }
  to {
    border-bottom: 2px solid rgb(0, 208, 80);
  }
}
.productDisplay .descriptionSec .ReviewDescripQuestion table {
  width: 100%;
}
.productDisplay .descriptionSec .ReviewDescripQuestion td,
.productDisplay .descriptionSec .ReviewDescripQuestion th {
  text-align: left;
  padding: 8px;
}
.productDisplay .descriptionSec .ReviewDescripQuestion th {
  border-bottom: 1px solid rgb(214, 214, 214);
}
.productDisplay .pictureSec {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 24px;
}
.productDisplay .pictureSec .productPic {
  background-color: gray;
  width: 80%;
  border-radius: 10px;
}
.productDisplay .pictureSec .productPic img {
  height: auto;
  max-width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.productDisplay .pictureSec .grid {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
}
.productDisplay .pictureSec .grid img {
  cursor: pointer;
  max-width: 150px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(47, 47, 47);
}

.RelatedProducts {
  width: 100%;
}
.RelatedProducts .upperSec {
  width: 90%;
  height: 50px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin: 0px 80px 0px;
}
.RelatedProducts .upperSec button {
  justify-self: end;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  border: none;
  cursor: pointer;
}/*# sourceMappingURL=productDisplay.css.map */