.Checkout {
    margin-top: 200px;
    display: flex;
    margin-left: 80px;
    column-gap: 32px;

    %titleLegend {
        .titleSec {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 24px;
                font-weight: bold;
            }

            .legend {
                opacity: 0.5;
                font-size: 12px;
            }
        }
    }

    .billingSec {
        width: fit-content;
        padding-bottom: 48px;

        .billingInfo {
            @extend %titleLegend;

            .info {
                display: grid;
                grid-template-columns: auto auto;
                margin-top: 24px;
                column-gap: 18px;
                row-gap: 32px;

                .inputFields {
                    display: flex;
                    flex-direction: column;

                    span {
                        font-weight: bold;
                    }
                }

                input {
                    padding: 0px 16px;
                    width: 319px;
                    height: 48px;
                    border-radius: 16px;
                    outline: none;
                    border: 2px solid #D1D1D1;
                }

            }

            button {
                border-radius: 10px;
                border: 2px solid #D1D1D1;
                padding: 15px;
                margin-top: 24px;
                background-color: #F9F9F9;
                display: flex;
                align-items: flex-end;
                column-gap: 8px;

                span {
                    font-size: 15px;
                }
            }
        }

        .shippingMethod {
            margin-top: 24px;
            @extend %titleLegend;

            .options {
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                row-gap: 16px;

                .shippingOption {
                    cursor: pointer;
                    width: 90%;
                    border: 2px solid #D1D1D1;
                    background-color: #F9F9F9;
                    border-radius: 16px;
                    height: 48px;
                    display: grid;
                    grid-template-columns: auto auto auto;
                    align-items: center;
                    padding: 0px 24px 0px 24px;

                    img {
                        justify-self: end;
                        max-width: 60px;
                    }

                    .companyName {
                        display: flex;
                        column-gap: 16px;
                        align-items: center;

                        .circle {
                            border: 2px solid rgb(204, 204, 204);
                            border-radius: 50%;
                            color: transparent;
                        }
                    }

                    .price {
                        display: flex;
                        column-gap: 16px;

                        .addedMoney {
                            color: rgb(0, 168, 25);
                            font-weight: bold;
                        }
                    }
                }
            }

        }

        .payementMethod {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            margin-top: 24px;
            @extend %titleLegend;

            label {
                font-size: 12px;
                font-weight: bold;
            }

            $carWidht: 500px;
            $paddingLeftRight: 16px;

            %inputField {
                border-radius: 10px;
                height: 32px;
                border: 2px solid rgb(190, 190, 190);
                outline: none;
                background-color: #F9F9F9;
                padding: 0px 16px;
            }

            %inputField:focus {
                background-color: transparent;
            }

            .card {


                width: $carWidht;
                border-radius: 10px;
                display: flex;
                row-gap: 10px;
                flex-direction: column;
                padding: $paddingLeftRight 19px $paddingLeftRight 19px;
                border: 1px solid rgb(190, 190, 190);

                .upperPart {
                    display: grid;
                    grid-template-columns: auto auto;
                    width: 100%;

                    img {
                        justify-self: flex-end;
                    }

                    .textArea {
                        display: flex;
                        column-gap: 8px;
                        align-items: center;
                    }
                }

                .cardNumberSec {
                    width: 100%;

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input {
                        width: $carWidht - ($paddingLeftRight*2);
                        @extend %inputField;
                    }


                }

                .bottomPart {
                    display: flex;
                    width: 100%;

                    input {
                        @extend %inputField;
                    }


                    .cardHolderSec {
                        input {
                            width: $carWidht/2.2;
                        }
                    }

                    .expirationDateSec {
                        align-self: center;
                        margin-left: 8px;

                        input {
                            width: 100px;
                        }
                    }

                    .cvcSec {
                        input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                        input {
                            width: 48px;
                        }
                    }
                }
            }

            .paypal {
                width: $carWidht;
                height: fit-content;
                border: 1px solid #D1D1D1;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                padding: 16px $paddingLeftRight 16px $paddingLeftRight;

                .inputSec {
                    margin: 16px 0px 16px 0px;
                    display: flex;
                    column-gap: 10px;

                    input {
                        padding: 0px 0px 0px 16px;
                        width: 200px;
                        outline: none;
                        border-radius: 10px;
                        height: 32px;
                        border: 2px solid #D1D1D1;
                    }
                }

                .upperPart {
                    width: 100%;
                    display: grid;
                    grid-template-columns: auto auto;

                    img {
                        justify-self: flex-end;
                    }
                }

                button {
                    width: 120px;
                    margin: 0px auto 0px auto;
                    border: 2px solid #46760A;
                    outline: none;
                    background-color: rgb(16, 155, 109);
                    color: #ffffff;
                    border-radius: 10px;
                    height: 32px;
                    cursor: pointer;
                }
            }

            .bitcoin {
                width: $carWidht;
                height: fit-content;
                border: 1px solid #D1D1D1;
                border-radius: 10px;
                padding: 16px $paddingLeftRight 16px $paddingLeftRight;

                .upperPart {
                    display: grid;
                    grid-template-columns: auto auto;
                    width: 100%;

                    img {
                        justify-self: flex-end;
                    }
                }

                .walletInfo {
                    margin: 0px auto 0px auto;
                    width: fit-content;

                    .title {
                        opacity: 0.7;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    }

                    %infoSec {
                        width: 300px;
                        border: 2px solid #D1D1D1;
                        border-radius: 10px;
                        background-color: #F9F9F9;
                        cursor: pointer;
                    }

                    .amounToPay {
                        @extend %infoSec;
                        margin-bottom: 16px;
                        padding: 8px 0px 8px 0px;
                        display: grid;
                        grid-template-columns: 60% 30% 10%;
                        align-items: center;

                        .btcValue {
                            justify-self: center;
                        }

                        .usdValue {
                            color: rgb(16, 155, 109);
                        }


                    }

                    .walletAddress {
                        @extend %infoSec;
                        padding: 8px 0px 8px 0px;
                        display: grid;
                        grid-template-columns: 90% 10%;

                        span {
                            justify-self: center;
                        }
                    }

                    .timer {
                        text-align: center;
                        margin: 8px 0px 0px 0px;

                        .time {
                            font-weight: bold;
                        }

                        .legend {
                            opacity: 0.5;
                        }
                    }
                }
            }

            .barDesign {
                height: 48px;
                width: 500px;
                display: grid;
                align-items: center;
                border-radius: 10px;
                padding: 0px 16px 0px 16px;
                grid-template-columns: auto auto;
                border: 1px solid rgb(190, 190, 190);
                cursor: pointer;

                .textArea {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                }

                img {
                    justify-self: flex-end;
                }
            }
        }

        .AdditionalInfo {
            @extend %titleLegend;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            margin-top: 24px;

            textarea {
                width: 100%;
                height: 120px;
                border-radius: 10px;
                border: 1px solid rgb(190, 190, 190);
                outline: none;
                resize: none;
                padding: 8px;
            }
        }

        .confirmation {
            %emailOption {
                display: flex;
                column-gap: 8px;
                background-color: #F9F9F9;
                border: 1px solid rgb(190, 190, 190);
                border-radius: 10px;
                padding: 8px 16px 8px 16px;
                width: fit-content
            }

            @extend %titleLegend;

            .termsToCheck {
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .marketingEmails {
                    @extend %emailOption;
                }

                .termCondition {
                    @extend %emailOption;
                }
            }

        }

        .btncompleted {
            margin-top: 24px;
            width: 218px;
            height: 56px;
            border-radius: 12px;
            border: 2px solid #46760A;
            background-color: #6A983C;
            color: white;
            font-weight: bold;
            cursor: pointer;
            font-size: 14px;
        }

        .btncompleted:hover {
            animation: btnComplete 0.5s forwards ease-in-out;
        }

        @keyframes btnComplete {
            to {
                background-color: #ffffff;
                color: #46760A;
            }
        }
    }

    .orderSummary {
        @extend %titleLegend;
        border: 1px solid #D1D1D1;
        border-radius: 12px;
        padding: 32px 16px 32px 16px;
        height: fit-content;

        .titleSec {
            margin: 0px 0px 24px 0px
        }

        .products {
            display: flex;
            column-gap: 16px;
            height: 170px;
            width: 436px;
            border-bottom: 1px solid #F9F9F9;
            padding-bottom: 16px;

            .leftSec {
                .baguette {
                    max-width: 100px;
                    border-radius: 10px;
                }

                .controlBtns {
                    display: flex;
                    flex-direction: column;

                    img {
                        max-width: 15px;
                    }

                    .xIcon {
                        font-size: 12px;
                    }

                    div {
                        display: flex;
                        column-gap: 6px;
                        align-items: center;
                    }
                }
            }

            .rightSec {
                display: flex;
                flex-direction: column;
                row-gap: 14px;
                width: 100%;

                .title {
                    font-weight: bold;
                    font-size: 18px;
                }

                .pricingPieces {
                    display: grid;
                    width: 100%;
                    grid-template-columns: auto auto;

                    .price {
                        display: flex;
                        flex-direction: column;
                        font-weight: bold;

                        .currentPrice {
                            font-size: 18px;
                            color: #6A983C;
                        }

                        .oldPrice {
                            font-size: 11px;
                            text-decoration: line-through;
                        }
                    }

                    .pieces {
                        width: 92px;
                        height: 32px;
                        justify-self: end;
                        align-items: center;
                        border-radius: 12px;
                        background-color: #F9F9F9;
                        border: 1px solid #D1D1D1;
                        display: grid;
                        grid-template-columns: auto auto auto;

                        .numPieces {
                            color: #D1D1D1;
                            justify-self: center;
                        }

                        .line {
                            height: 80%;
                            width: 1px;
                            background-color: #D1D1D1;
                        }

                        i {

                            font-size: 10px;
                        }

                        .pcs {
                            margin-right: 4px;
                            font-weight: bold;
                        }
                    }
                }

                .extraDetails {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .subTotal {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            margin-top: 16px;
            row-gap: 10px;
            font-size: 12px;

            div {
                display: grid;
                grid-template-columns: auto auto;

                .numericalVal {
                    justify-self: end;
                }

            }
        }

        .applyPromo {
            margin: 16px 0px 16px 0px;
            height: 42px;
            display: grid;
            grid-template-columns: 75% auto;
            background-color: #F9F9F9;
            border: 1px solid #D1D1D1;
            border-radius: 12px;

            input {
                background-color: transparent;
                outline: none;
                border: none;
                padding: 0px 0px 0px 21px;
            }

            div {
                align-self: center;
                font-weight: bold;
                margin: 0px 16px 0px 0px;
                justify-self: flex-end;
                cursor: pointer;
            }
        }

        .totalOrder {
            display: grid;
            grid-template-columns: auto auto;

            div {
                display: flex;
                flex-direction: column;

                .guarantee {
                    color: #6A983C;
                }
            }

            .totalPrice {
                font-size: 26px;
                align-self: flex-end;
                justify-self: end;
                font-weight: bold;
                color: #6A983C;
            }
        }
    }

}